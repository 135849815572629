<script lang="ts" setup>
import { GlobalModalKey } from '~/resources/modal/modal.interface';

// #region Globals
const route = useRoute();
const dayjs = useDayjs();
// #endregion

// #region Props & Emits
const props = defineProps({
   availabilityTodo: { type: Object as PropType<AvailabilityTodo>, required: true },
});
// #endregion

// #region AvailabilityTodo modal
const availabilityTodoModalPath = computed(() => {
   const hash = `${GlobalModalKey.InputAvailablities}?availabilityTodoId=${props.availabilityTodo.sync_id}`;
   return `${route.fullPath}${hash}`;
});
// #endregion

// #region Dates
const start = computed(() => dayjs(props.availabilityTodo.start_date));
const end = computed(() => dayjs(props.availabilityTodo.end_date));

const period = computed(() => {
   if (start.value.isSame(end.value, 'day')) return `${start.value.format('dd DD MMM')}`;
   if (start.value.isSame(end.value, 'month') && start.value.isSame(end.value, 'year') && start.value.isSame(dayjs(), 'year'))
      return `${start.value.format('dd DD')} - ${end.value.format('dd DD MMM')}`;
   if (start.value.isSame(end.value, 'month') && start.value.isSame(end.value, 'year'))
      return `${start.value.format('dd DD')} - ${end.value.format('dd DD MMM YYYY')}`;
   return `${start.value.format('dd DD MMM YYYY')} - ${end.value.format('dd DD MMM YYYY')}`;
});
// #endregion
</script>

<template>
   <NuxtLink :to="availabilityTodoModalPath" :class="'availability-todo-card'">
      <div class="availability-todo-card__body">
         <div class="availability-todo-card__greeting">
            <small> {{ availabilityTodo.availability_question }} </small>
         </div>

         <div class="availability-todo-card__content">
            <div class="availability-todo-card__logos">
               <LayoutCustomerLogos
                  v-if="availabilityTodo.tenant_logo || availabilityTodo.tenant_name"
                  :logos="[{ logo: availabilityTodo.tenant_logo, name: availabilityTodo.tenant_name }]"
               />
            </div>

            <div class="availability-todo-card__info">
               <p>{{ period }}</p>
               <p>
                  <small v-if="availabilityTodo.creator_first_name && availabilityTodo.tenant_name">
                     {{ availabilityTodo.creator_first_name }} | {{ availabilityTodo.tenant_name }}
                  </small>
                  <small v-else-if="availabilityTodo.creator_first_name">
                     {{ availabilityTodo.creator_first_name }}
                  </small>
                  <small v-else-if="availabilityTodo.tenant_name">
                     {{ availabilityTodo.tenant_name }}
                  </small>
               </p>
            </div>
         </div>
      </div>
   </NuxtLink>
</template>

<style lang="scss" scoped>
.availability-todo-card {
   width: 100%;

   background-color: var(--nxt-card-background);
   color: var(--nxt-dark);
   border-radius: var(--nxt-radius);
   padding: var(--nxt-gutter-small);

   text-decoration: none;
   display: flex;
   justify-content: space-between;

   &:visited,
   &:active,
   &:hover {
      text-decoration: none;
      color: var(--nxt-dark);
   }
}

.availability-todo-card__body {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter);
}

.availability-todo-card__actions {
   padding-right: var(--nxt-gutter);
}

.availability-todo-card__content {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter);
}

.availability-todo-card__info {
   p {
      margin: 0;

      &::first-letter {
         text-transform: capitalize;
      }
   }
}
</style>
